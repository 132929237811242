<template>
  <section>
    <headline-group
      sectionTitle="Una larga experiencia<br>en el sector"
      sectionSubTitle="La mayor calidad y durabilidad a su alcance"
      orientation="right"
    />
    <!-- Wrapper que define el área fija del parallax -->
    <div class="parallax-wrapper">
      <!-- Contenedor sticky: se queda en el viewport hasta que se acaba el wrapper -->
      <div class="image-container" ref="container">
        <video
          ref="video"
          src="../assets/video-flamenco.mp4"
          autoplay
          muted
          loop
          playsinline
        ></video>
        <div class="content">
          <h5>Construímos piscinas<br>desde 1991</h5>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import HeadlineGroup from "@/components/HeadlineGroup.vue";

export default {
  components: { HeadlineGroup },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    // Posición inicial
    this.handleScroll();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const container = this.$refs.container;
      const video = this.$refs.video;
      if (!container || !video) return;

      // Obtenemos la posición del contenedor sticky en relación al viewport
      const rect = container.getBoundingClientRect();
      const containerHeight = rect.height;

      // Altura extra del video (el video debe ser mayor que el contenedor)
      const videoHeight = video.offsetHeight;
      const extraHeight = videoHeight - containerHeight;
      if (extraHeight <= 0) return;

      // Calculamos el progreso del contenedor en el viewport.
      // Con un contenedor sticky dentro de un wrapper fijo, progress varía de 0 a 1
      const windowHeight = window.innerHeight;
      let progress = (windowHeight - rect.top) / (windowHeight + containerHeight);
      progress = Math.min(Math.max(progress, 0), 1);

      // Calculamos el desplazamiento del video. Si progress llega a 1, se detiene el movimiento.
      const offset = -extraHeight * progress;
      video.style.transform = `translateY(${offset}px)`;
    }
  }
};
</script>

<style scoped>
/* Wrapper para definir el área del efecto parallax (altura fija) */
.parallax-wrapper {
  position: relative;
  height: 80vh; /* Ajusta este valor según lo que necesites */
  overflow: hidden;
}

/* Contenedor sticky: se mantiene fijo en la parte superior del wrapper */
.image-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Importante para que el video se oculte al salirse */
  filter: brightness(90%);
}

/* El video tiene una altura mayor para disponer de "espacio extra" */
video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%; /* Puedes aumentar este valor para que el video se mueva más */
  object-fit: cover;
  transition: transform 0.1s ease-out;
}

.content {
  position: absolute;
  width: 75%;
  height: 50%;
  background-color: var(--main-faded-color2);
  z-index: 2;
  left: calc(50% - (75% / 2));
  top: calc(50% - (50% / 2));
  display: flex;
  justify-content: center;
  align-items: center;
}

h5 {
  text-align: center;
  font-size: var(--font-size-4);
  color: white;
  margin: 15px;
  line-height: var(--font-size-3);
}
</style>
